export const cargosOptions = [
  { id: 1, description: "AUXILIAR DE CRECHE" },
  { id: 2, description: "AUXILIAR DE SECRETARIA" },
  { id: 3, description: "CUIDADOR DE ALUNOS ESPECIAIS" },
  { id: 4, description: "DOCENTES I" },
  { id: 5, description: "DOCENTES II" },
  { id: 6, description: "INSPETOR DE ALUNOS" },
  { id: 7, description: "INSPETOR ESCOLAR" },
  { id: 8, description: "MERENDEIRA" },
  { id: 9, description: "MONITOR DE TRANSPORTE" },
  { id: 10, description: "MOTORISTA CLASSE D" },
  { id: 11, description: "NUTRICIONISTA" },
  { id: 12, description: "ORIENTADOR EDUCACIONAL" },
  { id: 13, description: "PORTEIRO" },
  { id: 14, description: "PSICOPEDAGOGO" },
  { id: 15, description: "SECRETÁRIO ESCOLAR" },
  { id: 16, description: "SERVENTE" },
  { id: 17, description: "SUPERVISOR PEDAGÓGICO" },
];

export const doisCursos = [
  { id: 0, description: "Nenhum" },
  { id: 1, description: "1 curso" },
  { id: 2, description: "2 cursos" },
];

export const doisTitulos = [
  { id: 0, description: "Nenhum" },
  { id: 1, description: "1 título" },
  { id: 2, description: "2 títulos" },
];

export const cincoCursos = [
  { id: 0, description: "Nenhum" },
  { id: 1, description: "1 curso" },
  { id: 2, description: "2 cursos" },
  { id: 3, description: "3 cursos" },
  { id: 4, description: "4 cursos" },
  { id: 5, description: "5 cursos" },
];

export const quatroCursos = [
  { id: 0, description: "Nenhum" },
  { id: 1, description: "1 curso" },
  { id: 2, description: "2 cursos" },
  { id: 3, description: "3 cursos" },
  { id: 4, description: "4 cursos" },
];

export const tresCursos = [
  { id: 0, description: "Nenhum" },
  { id: 1, description: "1 curso" },
  { id: 2, description: "2 cursos" },
  { id: 3, description: "3 cursos" },
];

export const experienceOptionsOutros = [
  { id: 0, description: "Nenhum" },
  { id: 1, description: "1 Semestre (6 meses)" },
  { id: 2, description: "2 Semestres (12 meses)" },
  { id: 3, description: "3 Semestres (1 ano e 6 meses)" },
  { id: 4, description: "4 Semestres (2 anos)" },
  { id: 5, description: "5 Semestres (2 anos e 6 meses)" },
];

export const experienceOptionsPrefeitura = [
  { id: 0, description: "Nenhum" },
  { id: 1, description: "1 Semestre (6 meses)" },
  { id: 2, description: "2 Semestres (12 meses)" },
  { id: 3, description: "3 Semestres (1 ano e 6 meses)" },
  { id: 4, description: "4 Semestres (2 anos)" },
  { id: 5, description: "5 Semestres (2 anos e 6 meses)" },
  { id: 6, description: "6 Semestres (3 anos)" },
  { id: 7, description: "7 Semestres (3 anos e 6 meses)" },
  { id: 8, description: "8 Semestres (4 anos)" },
  { id: 9, description: "9 Semestres (4 anos e 6 meses)" },
  { id: 10, description: "10 Semestres (5 anos)" },
];

export const disciplinasOptions = [
  { id: 1, description: "Arte" },
  { id: 2, description: "Ciências" },
  { id: 3, description: "Educação Física" },
  { id: 4, description: "Geografia" },
  { id: 5, description: "História" },
  { id: 6, description: "Língua Inglesa" },
  { id: 7, description: "Língua Portuguesa" },
  { id: 8, description: "Matemática" },
];

