import {
  Autocomplete,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from "@mui/material";
import { Controller } from "react-hook-form";
import {
  experienceOptionsOutros,
  experienceOptionsPrefeitura,
  quatroCursos,
} from "../data/options";

export function AuxiliarCrecheForm({
  control,
  errors,
  isPrint = false,
  values,
}) {
  return (
    <>
      <Grid item xs={12} sm={6}>
        <Typography marginBottom={2}>
          Experiência profissional no cargo pretendido na Prefeitura de São
          Pedro da Aldeia?
        </Typography>
        <Controller
          name="experiencePrefeitura"
          control={control}
          rules={{ required: "Experiência na Prefeitura é obrigatória." }}
          render={({ field }) =>
            isPrint ? (
              <TextField
                value={
                  experienceOptionsPrefeitura?.find(
                    (c) => c.id == values?.experiencePrefeitura
                  )?.description || 0
                }
                fullWidth
                variant="outlined"
                InputProps={{
                  readOnly: true,
                }}
              />
            ) : (
              <Autocomplete
                options={experienceOptionsPrefeitura}
                value={field.value.id}
                disableClearable
                inputValue={field.value.description}
                getOptionLabel={(option) => option.description}
                isOptionEqualToValue={(option, value) =>
                  option.id === value?.id
                }
                onChange={(event, newValue) =>
                  field.onChange(newValue ? newValue.id : null)
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Selecione uma opção..."
                    variant="outlined"
                    error={!!errors.experiencePrefeitura}
                    helperText={
                      errors.experiencePrefeitura
                        ? errors.experiencePrefeitura.message
                        : ""
                    }
                  />
                )}
              />
            )
          }
        />
      </Grid>

      <Grid item xs={12} sm={6}>
        <Typography marginBottom={2}>
          Experiência profissional no cargo pretendido em outros municípios ou
          instituições privadas?
        </Typography>
        <Controller
          name="experienceOutros"
          control={control}
          rules={{
            required: "Experiência em outros municípios é obrigatória.",
          }}
          render={({ field }) =>
            isPrint ? (
              <TextField
                value={
                  experienceOptionsOutros?.find(
                    (c) => c.id == values?.experienceOutros
                  )?.description || 0
                }
                fullWidth
                variant="outlined"
                InputProps={{
                  readOnly: true,
                }}
              />
            ) : (
              <Autocomplete
                options={experienceOptionsOutros}
                value={field.value.id}
                disableClearable
                inputValue={field.value.description}
                getOptionLabel={(option) => option.description}
                isOptionEqualToValue={(option, value) =>
                  option.id === value?.id
                }
                onChange={(event, newValue) =>
                  field.onChange(newValue ? newValue.id : null)
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Selecione uma opção..."
                    variant="outlined"
                    error={!!errors.experienceOutros}
                    helperText={
                      errors.experienceOutros
                        ? errors.experienceOutros.message
                        : ""
                    }
                  />
                )}
              />
            )
          }
        />
      </Grid>

      <Grid item xs={12} sm={6}>
        <Typography>Curso de Formação de Professores (nível médio)</Typography>
        <Controller
          name="cursoFormacaoProfessores"
          control={control}
          rules={{
            required: "É necessário indicar se possui curso de formação.",
          }}
          render={({ field }) =>
            isPrint ? (
              <RadioGroup row value={values.cursoFormacaoProfessores}>
                <FormControlLabel value="SIM" control={<Radio />} label="Sim" />
                <FormControlLabel value="NAO" control={<Radio />} label="Não" />
              </RadioGroup>
            ) : (
              <FormControl error={!!errors.cursoFormacaoProfessores}>
                <RadioGroup
                  row
                  {...field}
                  onChange={(e) => field.onChange(e.target.value)}
                >
                  <FormControlLabel
                    value="sim"
                    control={<Radio />}
                    label="Sim"
                  />
                  <FormControlLabel
                    value="nao"
                    control={<Radio />}
                    label="Não"
                  />
                </RadioGroup>
                {errors.cursoFormacaoProfessores && (
                  <FormHelperText>
                    {errors.cursoFormacaoProfessores.message}
                  </FormHelperText>
                )}
              </FormControl>
            )
          }
        />
      </Grid>

      <Grid item xs={12} sm={6}>
        <Typography marginBottom={2}>
          Cursos na área de atuação no cargo pretendido com carga horária mínima
          de 40h (quarenta horas) – por certificado - concluídos no período
          entre 2014 e a data inicial de inscrição deste processo seletivo
          simplificado
        </Typography>
        <Controller
          name="cursoCargoPretendido"
          control={control}
          rules={{ required: "Selecione um curso para a área de atuação." }}
          render={({ field }) =>
            isPrint ? (
              <TextField
                value={
                  quatroCursos?.find(
                    (c) => c.id == values?.cursoCargoPretendido
                  )?.description || 0
                }
                fullWidth
                variant="outlined"
                InputProps={{
                  readOnly: true,
                }}
              />
            ) : (
              <Autocomplete
                options={quatroCursos}
                value={field.value.id}
                disableClearable
                inputValue={field.value.description}
                getOptionLabel={(option) => option.description}
                isOptionEqualToValue={(option, value) =>
                  option.id === value?.id
                }
                onChange={(event, newValue) =>
                  field.onChange(newValue ? newValue.id : null)
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Selecione uma opção..."
                    variant="outlined"
                    error={!!errors.cursoCargoPretendido}
                    helperText={
                      errors.cursoCargoPretendido
                        ? errors.cursoCargoPretendido.message
                        : ""
                    }
                  />
                )}
              />
            )
          }
        />
      </Grid>

      <Grid item xs={12} sm={6}>
        <Typography marginBottom={2}>
          Cursos na área de <strong>Educação Especial</strong> com documento
          comprobatório de conclusão, com carga horária mínima de 40h (quarenta
          horas) – por certificado - concluídos no período entre 2014 e a data
          inicial de inscrição deste processo seletivo simplificado
        </Typography>
        <Controller
          name="cursoEducacaoEspecial"
          control={control}
          rules={{
            required: "Selecione um curso na área de Educação Especial.",
          }}
          render={({ field }) =>
            isPrint ? (
              <TextField
                value={
                  quatroCursos?.find(
                    (c) => c.id == values?.cursoEducacaoEspecial
                  )?.description || 0
                }
                fullWidth
                variant="outlined"
                InputProps={{
                  readOnly: true,
                }}
              />
            ) : (
              <Autocomplete
                options={quatroCursos}
                value={field.value.id}
                disableClearable
                inputValue={field.value.description}
                getOptionLabel={(option) => option.description}
                isOptionEqualToValue={(option, value) =>
                  option.id === value?.id
                }
                onChange={(event, newValue) =>
                  field.onChange(newValue ? newValue.id : null)
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Selecione uma opção..."
                    variant="outlined"
                    error={!!errors.cursoEducacaoEspecial}
                    helperText={
                      errors.cursoEducacaoEspecial
                        ? errors.cursoEducacaoEspecial.message
                        : ""
                    }
                  />
                )}
              />
            )
          }
        />
      </Grid>
    </>
  );
}
