import React from "react";
import { Route } from "react-router-dom";
import urlsBilingue from "../pages/formularios/bilingue/UrlsBilingue";
import BilingueHomePage from "../pages/formularios/bilingue/pages/BilingueHomePage";
import BilingueFormularioPage from "../pages/formularios/bilingue/pages/BilingueFormularioPage";
import BilingueVisualizacaoPage from "../pages/formularios/bilingue/pages/BilingueVisualizacaoPage";
import UrlsProcessoSeletivoServidores from "../pages/formularios/servidores/UrlsProcessoSeletivoServidores";
import ProcessoSeletivoServidoresPage from "../pages/formularios/servidores/pages/ProcessoSeletivoServidoresPage";
import ProcessoSeletivoServidoresHome from "../pages/formularios/servidores/pages/ProcessoSeletivoHome";
import ServidoresDashboardPage from "../pages/formularios/servidores/pages/ServidoresDashboardPage";
import ServidoresInscricoesPage from "../pages/formularios/servidores/pages/ServidoresInscricoesPage";
import ProcessoSeletivoServidoresPageVisualizacao from "../pages/formularios/servidores/pages/ProcessoSeletivoServidoresPageVisualizacao";

const enableSeduc =
  process.env.REACT_APP_SECRETARIA_MUNICIPAL === "SEDUC" ||
  process.env.REACT_APP_SECRETARIA_MUNICIPAL === "R3MAIS";
const dataAberturaBilingue = new Date("2024-10-29T09:00:00");
const dataFechamentoBilingue = new Date("2024-11-01T17:00:00");
const enableSemed =
  process.env.REACT_APP_SECRETARIA_MUNICIPAL === "SEMED" ||
  process.env.REACT_APP_SECRETARIA_MUNICIPAL === "R3MAIS";
const dataAberturaServidores = new Date("2024-10-30T09:00:00");
const dataFechamentoServidores = new Date("2024-11-20T17:00:00");

const FormulariosRoute = () => (
  <React.Fragment>
    {enableSeduc && (
      <>
        <Route
          path={urlsBilingue.formulario}
          element={
            <BilingueHomePage
              dataAbertura={dataAberturaBilingue}
              dataFechamento={dataFechamentoBilingue}
            />
          }
        />
        <Route
          path={urlsBilingue.formularioVisualizacaoId}
          element={<BilingueVisualizacaoPage />}
        />
      </>
    )}

    {enableSemed && (
      <>
        <Route
          path={UrlsProcessoSeletivoServidores.formulario}
          element={
            <ProcessoSeletivoServidoresHome
              dataAbertura={dataAberturaServidores}
              dataFechamento={dataFechamentoServidores}
            />
          }
        />
        <Route
          path={UrlsProcessoSeletivoServidores.dashboard}
          element={
            <ServidoresDashboardPage
            />
          }
        />
        <Route
          path={UrlsProcessoSeletivoServidores.inscricoes}
          element={
            <ServidoresInscricoesPage
            />
          }
        />
      </>
    )}

    {enableSeduc &&
      new Date() >= dataAberturaBilingue &&
      new Date() < dataFechamentoBilingue && (
        <>
          <Route
            path={urlsBilingue.formularioMilitar}
            element={
              <BilingueFormularioPage
                tipoFormulario={"BILINGUE_MILITAR"}
                dataFechamento={dataFechamentoBilingue}
              />
            }
          />
          <Route
            path={urlsBilingue.formularioGastronomiaHotelaria}
            element={
              <BilingueFormularioPage
                tipoFormulario={"BILINGUE_GASTRONOMIA_HOTELARIA"}
                dataFechamento={dataFechamentoBilingue}
              />
            }
          />
          <Route
            path={urlsBilingue.formularioAgronegocioTurismo}
            element={
              <BilingueFormularioPage
                tipoFormulario={"BILINGUE_AGRONEGOCIO_TURISMO_RURAL"}
                dataFechamento={dataFechamentoBilingue}
              />
            }
          />
        </>
      )}

    {enableSemed &&
      new Date() >= dataAberturaServidores &&
      new Date() < dataFechamentoServidores && (
        <>
          <Route
            path={UrlsProcessoSeletivoServidores.formularioPreenchimento}
            element={
              <ProcessoSeletivoServidoresPage
                dataFechamento={dataFechamentoServidores}
              />
            }
          />
        </>
      )}
      {enableSemed && (
        <>
          <Route
            path={UrlsProcessoSeletivoServidores.formularioVisualizacaoId}
            element={
              <ProcessoSeletivoServidoresPageVisualizacao
              />
            }
          />
        </>
      )}
  </React.Fragment>
);

export default FormulariosRoute;
