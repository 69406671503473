import React, { useEffect, useState } from "react";
import { Grid, Card, CardContent, Typography, Box } from "@mui/material";
import BaseFormularioServidores from "../BaseFormularioServidores";
import { listarEstatisticasServidores } from "../../../../services/formulariosService";
import { cargosOptions } from "../data/options";
import RestaurantIcon from "@mui/icons-material/Restaurant";
import SchoolIcon from "@mui/icons-material/School";
import AccessibilityIcon from "@mui/icons-material/Accessibility";
import LocalHospitalIcon from "@mui/icons-material/LocalHospital";
import PeopleIcon from "@mui/icons-material/People";
import WorkIcon from "@mui/icons-material/Work";
import SecurityIcon from "@mui/icons-material/Security";
import KitchenIcon from "@mui/icons-material/Kitchen";
import DirectionsBusIcon from "@mui/icons-material/DirectionsBus";
import DriveEtaIcon from "@mui/icons-material/DriveEta";
import FastfoodIcon from "@mui/icons-material/Fastfood";
import PersonPinIcon from "@mui/icons-material/PersonPin";
import LockIcon from "@mui/icons-material/Lock";
import AssignmentIndIcon from "@mui/icons-material/AssignmentInd";
import AssignmentIcon from "@mui/icons-material/Assignment";
import CleaningServicesIcon from "@mui/icons-material/CleaningServices";
import SupervisorAccountIcon from "@mui/icons-material/SupervisorAccount";

const ServidoresDashboardPage = () => {
  const [estatisticas, setEstatisticas] = useState([]);

  const cargoIcons = {
    1: <RestaurantIcon style={{ fontSize: 50, color: "#ff5722" }} />, // AUXILIAR DE CRECHE
    2: <AccessibilityIcon style={{ fontSize: 50, color: "#3f51b5" }} />, // AUXILIAR DE SECRETARIA
    3: <SchoolIcon style={{ fontSize: 50, color: "#4caf50" }} />, // CUIDADOR DE ALUNOS ESPECIAIS
    4: <LocalHospitalIcon style={{ fontSize: 50, color: "#e91e63" }} />, // DOCENTES I
    5: <PeopleIcon style={{ fontSize: 50, color: "#2196f3" }} />, // DOCENTES II
    6: <WorkIcon style={{ fontSize: 50, color: "#ff9800" }} />, // INSPETOR DE ALUNOS
    7: <SecurityIcon style={{ fontSize: 50, color: "#9c27b0" }} />, // INSPETOR ESCOLAR
    8: <KitchenIcon style={{ fontSize: 50, color: "#795548" }} />, // MERENDEIRA
    9: <DirectionsBusIcon style={{ fontSize: 50, color: "#009688" }} />, // MONITOR DE TRANSPORTE
    10: <DriveEtaIcon style={{ fontSize: 50, color: "#673ab7" }} />, // MOTORISTA CLASSE D
    11: <FastfoodIcon style={{ fontSize: 50, color: "#cddc39" }} />, // NUTRICIONISTA
    12: <PersonPinIcon style={{ fontSize: 50, color: "#00bcd4" }} />, // ORIENTADOR EDUCACIONAL
    13: <LockIcon style={{ fontSize: 50, color: "#ff5722" }} />, // PORTEIRO
    14: <AssignmentIndIcon style={{ fontSize: 50, color: "#4caf50" }} />, // PSICOPEDAGOGO
    15: <AssignmentIcon style={{ fontSize: 50, color: "#3f51b5" }} />, // SECRETÁRIO ESCOLAR
    16: <CleaningServicesIcon style={{ fontSize: 50, color: "#8bc34a" }} />, // SERVENTE
    17: <SupervisorAccountIcon style={{ fontSize: 50, color: "#e91e63" }} />, // SUPERVISOR PEDAGÓGICO
  };

  useEffect(() => {
    listarEstatisticasServidores().then((response) => {
      if (response?.data) {
        setEstatisticas(response.data);
      }
    });
  }, []);

  const getCargoDescription = (cargoPretendido) => {
    const cargo = cargosOptions.find((item) => item.id === cargoPretendido);
    return cargo ? cargo.description : "Unknown";
  };

  return (
    <BaseFormularioServidores>
      <Grid container spacing={3}>
        {estatisticas.map((estatistica) => (
          <Grid item xs={12} sm={6} md={4} key={estatistica.cargoPretendido}>
            <Card
              sx={{
                backgroundColor: "#f5f5f5",
                borderLeft: `5px solid ${
                  cargoIcons[estatistica.cargoPretendido]?.props?.style?.color || "#000"
                }`,
              }}
            >
              <CardContent>
                <Box display="flex" alignItems="center">
                  {cargoIcons[estatistica.cargoPretendido] || (
                    <WorkIcon style={{ fontSize: 50, color: "#9e9e9e" }} />
                  )}
                  <Box ml={2}>
                    <Typography variant="h6" component="div">
                      {getCargoDescription(estatistica.cargoPretendido)}
                    </Typography>
                    <Typography variant="h5">{estatistica.count}</Typography>
                  </Box>
                </Box>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>
    </BaseFormularioServidores>
  );
};

export default ServidoresDashboardPage;
