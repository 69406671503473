import {
  Autocomplete,
  Grid,
  TextField,
  Typography,
  FormControl,
  FormHelperText,
} from "@mui/material";
import { Controller } from "react-hook-form";
import {
  cincoCursos,
  experienceOptionsOutros,
  experienceOptionsPrefeitura,
} from "../data/options";

export function InspetorMonitorPorteiroServenteMerendeiraForm({
  control,
  errors,
  isPrint = false,
  values,
}) {
  return (
    <>
      <Grid item xs={12} sm={6}>
        <Typography marginBottom={2}>
          Experiência profissional no cargo pretendido na Prefeitura de São
          Pedro da Aldeia?
        </Typography>
        <Controller
          name="experiencePrefeitura"
          control={control}
          rules={{ required: "Experiência na Prefeitura é obrigatória." }}
          render={({ field }) =>
            isPrint ? (
              <TextField
                value={
                  experienceOptionsPrefeitura?.find(
                    (c) => c.id == values?.experiencePrefeitura
                  )?.description || 0
                }
                fullWidth
                variant="outlined"
                InputProps={{
                  readOnly: true,
                }}
              />
            ) : (
              <Autocomplete
                options={experienceOptionsPrefeitura}
                value={field.value.id}
                disableClearable
                inputValue={field.value.description}
                getOptionLabel={(option) => option.description}
                isOptionEqualToValue={(option, value) =>
                  option.id === value?.id
                }
                onChange={(event, newValue) =>
                  field.onChange(newValue ? newValue.id : null)
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Selecione uma opção..."
                    variant="outlined"
                    error={!!errors.experiencePrefeitura}
                    helperText={
                      errors.experiencePrefeitura
                        ? errors.experiencePrefeitura.message
                        : ""
                    }
                  />
                )}
              />
            )
          }
        />
      </Grid>

      <Grid item xs={12} sm={6}>
        <Typography marginBottom={2}>
          Experiência profissional no cargo pretendido em outros municípios ou
          instituições privadas?
        </Typography>
        <Controller
          name="experienceOutros"
          control={control}
          rules={{
            required: "Experiência em outros municípios é obrigatória.",
          }}
          render={({ field }) =>
            isPrint ? (
              <TextField
                value={
                  experienceOptionsOutros?.find(
                    (c) => c.id == values?.experienceOutros
                  )?.description || 0
                }
                fullWidth
                variant="outlined"
                InputProps={{
                  readOnly: true,
                }}
              />
            ) : (
              <Autocomplete
                options={experienceOptionsOutros}
                value={field.value.id}
                disableClearable
                inputValue={field.value.description}
                getOptionLabel={(option) => option.description}
                isOptionEqualToValue={(option, value) =>
                  option.id === value?.id
                }
                onChange={(event, newValue) =>
                  field.onChange(newValue ? newValue.id : null)
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Selecione uma opção..."
                    variant="outlined"
                    error={!!errors.experienceOutros}
                    helperText={
                      errors.experienceOutros
                        ? errors.experienceOutros.message
                        : ""
                    }
                  />
                )}
              />
            )
          }
        />
      </Grid>

      <Grid item xs={12} sm={6}>
        <Typography>
          Cursos de aperfeiçoamento, na área de atuação no cargo pretendido ou
          na área da educação de 40h (quarenta horas) – por certificado -
          concluídos no período entre 2014 e a data inicial de inscrição deste
          processo seletivo simplificado
        </Typography>
        <Controller
          name="cursoCargoPretendido"
          control={control}
          rules={{ required: "Curso de aperfeiçoamento é obrigatório." }}
          render={({ field }) =>
            isPrint ? (
              <TextField
                value={
                  cincoCursos?.find((c) => c.id == values?.cursoCargoPretendido)
                    ?.description || 0
                }
                fullWidth
                variant="outlined"
                InputProps={{
                  readOnly: true,
                }}
              />
            ) : (
              <Autocomplete
                options={cincoCursos}
                value={field.value.id}
                disableClearable
                inputValue={field.value.description}
                getOptionLabel={(option) => option.description}
                isOptionEqualToValue={(option, value) =>
                  option.id === value?.id
                }
                onChange={(event, newValue) =>
                  field.onChange(newValue ? newValue.id : null)
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Selecione uma opção..."
                    variant="outlined"
                    error={!!errors.cursoCargoPretendido}
                    helperText={
                      errors.cursoCargoPretendido
                        ? errors.cursoCargoPretendido.message
                        : ""
                    }
                  />
                )}
              />
            )
          }
        />
      </Grid>
    </>
  );
}
