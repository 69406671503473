import {
  Autocomplete,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from "@mui/material";
import { Controller } from "react-hook-form";
import {
  doisCursos,
  doisTitulos,
  experienceOptionsOutros,
  experienceOptionsPrefeitura,
} from "../data/options";

export function CuidadorAlunoEspecialForm({
  control,
  errors,
  isPrint = false,
  values,
}) {
  return (
    <>
      <Grid item xs={12} sm={6}>
        <Typography marginBottom={2}>
          Experiência profissional no cargo pretendido na Prefeitura de São
          Pedro da Aldeia?
        </Typography>
        <Controller
          name="experiencePrefeitura"
          control={control}
          rules={{ required: "Experiência na Prefeitura é obrigatória." }}
          render={({ field }) =>
            isPrint ? (
              <TextField
                value={
                  experienceOptionsPrefeitura?.find(
                    (c) => c.id == values?.experiencePrefeitura
                  )?.description || 0
                }
                fullWidth
                variant="outlined"
                InputProps={{
                  readOnly: true,
                }}
              />
            ) : (
              <Autocomplete
                options={experienceOptionsPrefeitura}
                value={field.value.id}
                disableClearable
                inputValue={field.value.description}
                getOptionLabel={(option) => option.description}
                isOptionEqualToValue={(option, value) =>
                  option.id === value?.id
                }
                onChange={(event, newValue) =>
                  field.onChange(newValue ? newValue.id : null)
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Selecione uma opção..."
                    variant="outlined"
                    error={!!errors.experiencePrefeitura}
                    helperText={
                      errors.experiencePrefeitura
                        ? errors.experiencePrefeitura.message
                        : ""
                    }
                  />
                )}
              />
            )
          }
        />
      </Grid>

      <Grid item xs={12} sm={6}>
        <Typography marginBottom={2}>
          Experiência profissional no cargo pretendido em outros municípios ou
          instituições privadas?
        </Typography>
        <Controller
          name="experienceOutros"
          control={control}
          rules={{
            required: "Experiência em outros municípios é obrigatória.",
          }}
          render={({ field }) =>
            isPrint ? (
              <TextField
                value={
                  experienceOptionsOutros?.find(
                    (c) => c.id == values?.experienceOutros
                  )?.description || 0
                }
                fullWidth
                variant="outlined"
                InputProps={{
                  readOnly: true,
                }}
              />
            ) : (
              <Autocomplete
                options={experienceOptionsOutros}
                value={field.value.id}
                disableClearable
                inputValue={field.value.description}
                getOptionLabel={(option) => option.description}
                isOptionEqualToValue={(option, value) =>
                  option.id === value?.id
                }
                onChange={(event, newValue) =>
                  field.onChange(newValue ? newValue.id : null)
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Selecione uma opção..."
                    variant="outlined"
                    error={!!errors.experienceOutros}
                    helperText={
                      errors.experienceOutros
                        ? errors.experienceOutros.message
                        : ""
                    }
                  />
                )}
              />
            )
          }
        />
      </Grid>

      <Grid item xs={12} sm={6}>
        <Typography>
          Possui Curso de Graduação em Pedagogia - concluído?
        </Typography>
        <Controller
          name="graduacaoPedagogia"
          control={control}
          rules={{
            required: "É necessário informar se possui graduação em Pedagogia.",
          }}
          render={({ field }) =>
            isPrint ? (
              <RadioGroup row value={values.graduacaoPedagogia}>
                <FormControlLabel value="SIM" control={<Radio />} label="Sim" />
                <FormControlLabel value="NAO" control={<Radio />} label="Não" />
              </RadioGroup>
            ) : (
              <FormControl
                component="fieldset"
                error={!!errors.graduacaoPedagogia}
              >
                <RadioGroup
                  row
                  {...field}
                  onChange={(e) => field.onChange(e.target.value)}
                >
                  <FormControlLabel
                    value="sim"
                    control={<Radio />}
                    label="Sim"
                  />
                  <FormControlLabel
                    value="nao"
                    control={<Radio />}
                    label="Não"
                  />
                </RadioGroup>
                {errors.graduacaoPedagogia && (
                  <FormHelperText>
                    {errors.graduacaoPedagogia.message}
                  </FormHelperText>
                )}
              </FormControl>
            )
          }
        />
      </Grid>

      <Grid item xs={12} sm={6}>
        <Typography marginBottom={2}>
          Curso de pós-graduação ou “lato-sensu” na área de educação especial
          com um mínimo de 360 (trezentos e sessenta) horas, por cada título?
        </Typography>
        <Controller
          name="posGraduacaoEducacaoEspecial"
          control={control}
          rules={{ required: "Selecione um curso de pós-graduação." }}
          render={({ field }) =>
            isPrint ? (
              <TextField
                value={
                  doisTitulos?.find(
                    (c) => c.id == values?.posGraduacaoEducacaoEspecial
                  )?.description || 0
                }
                fullWidth
                variant="outlined"
                InputProps={{
                  readOnly: true,
                }}
              />
            ) : (
              <Autocomplete
                options={doisTitulos}
                value={field.value.id}
                disableClearable
                inputValue={field.value.description}
                getOptionLabel={(option) => option.description}
                isOptionEqualToValue={(option, value) =>
                  option.id === value?.id
                }
                onChange={(event, newValue) =>
                  field.onChange(newValue ? newValue.id : null)
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Selecione uma opção..."
                    variant="outlined"
                    error={!!errors.posGraduacaoEducacaoEspecial}
                    helperText={
                      errors.posGraduacaoEducacaoEspecial
                        ? errors.posGraduacaoEducacaoEspecial.message
                        : ""
                    }
                  />
                )}
              />
            )
          }
        />
      </Grid>

      <Grid item xs={12} sm={6}>
        <Typography>
          Possui Curso de Formação de Professores (nível médio)?
        </Typography>
        <Controller
          name="cursoFormacaoProfessores"
          control={control}
          rules={{
            required:
              "É necessário informar se possui Curso de Formação de Professores.",
          }}
          render={({ field }) =>
            isPrint ? (
              <RadioGroup row value={values.cursoFormacaoProfessores}>
                <FormControlLabel value="SIM" control={<Radio />} label="Sim" />
                <FormControlLabel value="NAO" control={<Radio />} label="Não" />
              </RadioGroup>
            ) : (
              <FormControl
                component="fieldset"
                error={!!errors.cursoFormacaoProfessores}
              >
                <RadioGroup
                  row
                  {...field}
                  onChange={(e) => field.onChange(e.target.value)}
                >
                  <FormControlLabel
                    value="sim"
                    control={<Radio />}
                    label="Sim"
                  />
                  <FormControlLabel
                    value="nao"
                    control={<Radio />}
                    label="Não"
                  />
                </RadioGroup>
                {errors.cursoFormacaoProfessores && (
                  <FormHelperText>
                    {errors.cursoFormacaoProfessores.message}
                  </FormHelperText>
                )}
              </FormControl>
            )
          }
        />
      </Grid>

      <Grid item xs={12} sm={6}>
        <Typography>Técnico em Enfermagem - concluído?</Typography>
        <Controller
          name="cursoEnfermagem"
          control={control}
          rules={{
            required:
              "É necessário informar se possui curso técnico em enfermagem.",
          }}
          render={({ field }) =>
            isPrint ? (
              <RadioGroup row value={values.cursoEnfermagem}>
                <FormControlLabel value="SIM" control={<Radio />} label="Sim" />
                <FormControlLabel value="NAO" control={<Radio />} label="Não" />
              </RadioGroup>
            ) : (
              <FormControl
                component="fieldset"
                error={!!errors.cursoEnfermagem}
              >
                <RadioGroup
                  row
                  {...field}
                  onChange={(e) => field.onChange(e.target.value)}
                >
                  <FormControlLabel
                    value="sim"
                    control={<Radio />}
                    label="Sim"
                  />
                  <FormControlLabel
                    value="nao"
                    control={<Radio />}
                    label="Não"
                  />
                </RadioGroup>
                {errors.cursoEnfermagem && (
                  <FormHelperText>
                    {errors.cursoEnfermagem.message}
                  </FormHelperText>
                )}
              </FormControl>
            )
          }
        />
      </Grid>

      <Grid item xs={12} sm={6}>
        <Typography marginBottom={2}>
          Cursos de Capacitação na área de Educação Especial e Inclusiva
          (Braille, Soroban, Libras, etc.) - com carga horária mínima de 40h
          (quarenta horas) - por certificado - concluídos no período entre 2014
          e a data inicial de inscrição deste processo seletivo simplificado
        </Typography>
        <Controller
          name="cursoEducacaoEspecial"
          control={control}
          rules={{ required: "Selecione um curso de Educação Especial." }}
          render={({ field }) =>
            isPrint ? (
              <TextField
                value={
                  doisCursos?.find((c) => c.id == values?.cursoEducacaoEspecial)
                    ?.description || ""
                }
                fullWidth
                variant="outlined"
                InputProps={{
                  readOnly: true,
                }}
              />
            ) : (
              <Autocomplete
                options={doisCursos}
                value={field.value.id}
                disableClearable
                inputValue={field.value.description}
                getOptionLabel={(option) => option.description}
                isOptionEqualToValue={(option, value) =>
                  option.id === value?.id
                }
                onChange={(event, newValue) =>
                  field.onChange(newValue ? newValue.id : null)
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Selecione uma opção..."
                    variant="outlined"
                    error={!!errors.cursoEducacaoEspecial}
                    helperText={
                      errors.cursoEducacaoEspecial
                        ? errors.cursoEducacaoEspecial.message
                        : ""
                    }
                  />
                )}
              />
            )
          }
        />
      </Grid>
    </>
  );
}
