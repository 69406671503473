import React, { useState, useEffect } from 'react';
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Button, Typography, TextField, Autocomplete } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { getServidores } from '../../../services/servidores';
import { store } from '../../../store';

// Estilos customizados para um layout mais moderno e minimalista
const useStyles = makeStyles(() => ({
  dialogTitle: {
    textAlign: 'center',
    fontWeight: 'bold',
    fontSize: '1.5rem',
    color: '#333',
    marginBottom: '1rem',
  },
  dialogContent: {
    textAlign: 'center',
    fontSize: '1.1rem',
    color: '#666',
    padding: '20px',
  },
  dialogActions: {
    justifyContent: 'center',
    padding: '16px',
  },
  closeButton: {
    backgroundColor: '#1976d2',
    color: '#fff',
    '&:hover': {
      backgroundColor: '#C01515',
    },
    padding: '8px 24px',
    fontSize: '1rem',
    textTransform: 'none',
    borderRadius: '20px',
  },
  saveButton: {
    backgroundColor: '#1976d2',
    color: '#fff',
    '&:hover': {
      backgroundColor: '#1565c0',
    },
    padding: '8px 24px',
    fontSize: '1rem',
    textTransform: 'none',
    borderRadius: '20px',
  },
}));

const DialogGrupoChat = ({ isOpen, setOpenModal, salvaGrupo }) => {
  const classes = useStyles();
  const [data, setData] = useState([]);
  const [listaPessoas, setListaPessoas] = useState([]);
  const [pessoas, setPessoas] = useState([]);
  const [titulo, setTitulo] = useState("");
  const unidadeId = JSON.parse(localStorage.getItem('unidadeID'));
  const currentUser = store.getState()["user"]["user"];

  const handleClose = () => {
    setPessoas([]);
    setTitulo('');
    setOpenModal(false);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        await filtraContatos();
      } catch (error) {
        console.error('Erro ao buscar dados:', error);
      }
    };
    fetchData();

  }, [])

  const filtraContatos = async () => {
    await getServidores(0, 0, '', 2000, 0).then((response) => {
      var items = [];
      response.data.forEach(item => {
        if (item.id != currentUser.servidorId)
          items.push(item.nomeCompleto);
      });
      setData([...response.data]);
      setListaPessoas([...items]);
    });
  }

  const salvar = () => {
    var ids = [];
    var tituloInformado = titulo;
    pessoas.forEach(pessoa => {
      ids.push(data.find(x => x.nomeCompleto == pessoa).id);
    });
    setPessoas([]);
    setTitulo('');
    salvaGrupo(tituloInformado, ids);
  }

  return (
    <Dialog open={isOpen} onClose={handleClose} PaperProps={{ style: { borderRadius: '12px', padding: '10px' } }}>
      <DialogTitle className={classes.dialogTitle}>Criar Grupo
      </DialogTitle>
      <DialogContent className={classes.dialogContent}>
        <Typography variant="body1" style={{ width: 500 }}>
          Informe o nome do grupo
        </Typography>
        <div className='row'>
          <div className='col-12'>
            <input id='mensagem'
              type="text"
              className="form-control"
              placeholder="Escreva o título aqui"
              value={titulo}
              onChange={(e) => setTitulo(e.target.value)} />
          </div>
        </div>
        <div className='row' style={{ marginTop: 10 }}>
          <div className='col-12'>
            <Autocomplete
              multiple
              options={listaPessoas}
              getOptionLabel={(option) => option}
              disableCloseOnSelect
              value={pessoas}
              onChange={(event, newValue) => {
                setPessoas(newValue);
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  label="Selecione as pessoas"
                  placeholder="Selecione as pessoas"
                />
              )}
            />
          </div>
        </div>
      </DialogContent>
      <DialogActions className={classes.dialogActions}>
        <Button onClick={handleClose} className={classes.closeButton} >
          Fechar
        </Button>
        <Button onClick={() => { salvar() }} className={classes.saveButton}>
          Criar grupo
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DialogGrupoChat;
