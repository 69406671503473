import React, { useRef, useState, useEffect } from "react";
import {
  Container,
  Grid,
  FormControlLabel,
  Checkbox,
  Typography,
  Button,
  Card,
  CardContent,
  Box,
  Divider,
  FormHelperText,
  TextField,
  Radio,
  RadioGroup,
  Table,
  CircularProgress,
  TableHead,
  TableRow,
  TableBody,
  TableCell,
} from "@mui/material";
import { cargosOptions } from "../data/options";
import { LocationApi } from "../../../../services/external/cep";
import { useForm } from "react-hook-form";
import { CuidadorAlunoEspecialForm } from "../components/cuidadorAlunoEspecialForm";
import { AuxiliarDeSecretariaForm } from "../components/auxiliarDeSecretariaForm";
import { InspetorMonitorPorteiroServenteMerendeiraForm } from "../components/inspetorMonitorPorteiroServenteMerendeiraForm";
import { OrientadorSupervisorInspetorForm } from "../components/orientadorSupervisorInspetorForm";
import { SecretarioEscolarForm } from "../components/secretarioEscolarForm";
import { AuxiliarCrecheForm } from "../components/auxiliarCrecheForm";
import { MotoristaClasseDForm } from "../components/motoristaClasseD";
import { NutricionistaForm } from "../components/nutricionistaForm";
import { PsicopedagogoForm } from "../components/psicopedagogoForm";
import { DocenteIForm } from "../components/docenteIForm";
import { DocenteIIForm } from "../components/docenteIIForm";
import { listarFormularioServidores } from "../../../../services/formulariosService";
import { useParams } from "react-router-dom";
import { useReactToPrint } from "react-to-print";
import InputMask from "react-input-mask";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { format } from "date-fns";
import { ptBR } from "date-fns/locale";
import { useNavigate } from "react-router-dom";
import UrlsProcessoSeletivoServidores from "../UrlsProcessoSeletivoServidores";

const ProcessoSeletivoServidoresPageVisualizacao = () => {
  const {
    control,
    setValue,
    formState: { errors },
    watch,
  } = useForm();

  const { current: locationApi } = useRef(new LocationApi());

  const { id } = useParams();
  const navigate = useNavigate();

  const [formValues, setFormValues] = useState({});
  const [loading, setLoading] = useState(true);
  const [outrasInscricoes, setOutrasInscricoes] = useState([]);
  function formatDateTime(dateString) {
    return format(new Date(dateString), "dd/MM/yyyy 'às' HH:mm", {
      locale: ptBR,
    });
  }

  const componentRef = useRef();

  const handleNavigate = (id) => {
    window.open(
      `${UrlsProcessoSeletivoServidores.formularioVisualizacao + id}`,
      "_blank"
    );
  };

  useEffect(() => {
    const fetchFormulario = async () => {
      try {
        const response = await listarFormularioServidores(id);
        setFormValues(response?.data);
        setOutrasInscricoes(response?.data?.outrasInscricoes);
      } catch (error) {
        console.error("Erro ao buscar formulário:", error);
      } finally {
        setLoading(false); // Set loading to false after data is fetched
      }
    };
    fetchFormulario();
  }, [id]);

  const handlePrint = useReactToPrint({
    content: () => componentRef.current, // Define the content to print
    documentTitle: "FormularioBilingue2024", // Optional: define a document title
  });

  const renderCargoComponent = () => {
    switch (formValues.cargoPretendido) {
      case 1:
        return (
          <AuxiliarCrecheForm
            control={control}
            errors={errors}
            isPrint
            values={formValues}
          />
        );
      case 2:
        return (
          <AuxiliarDeSecretariaForm
            control={control}
            errors={errors}
            isPrint
            values={formValues}
          />
        );
      case 3:
        return (
          <CuidadorAlunoEspecialForm
            control={control}
            errors={errors}
            isPrint
            values={formValues}
          />
        );
      case 4:
        return (
          <DocenteIForm
            control={control}
            errors={errors}
            isPrint
            values={formValues}
          />
        );
      case 5:
        return (
          <DocenteIIForm
            control={control}
            errors={errors}
            isPrint
            values={formValues}
          />
        );
      case 10:
        return (
          <MotoristaClasseDForm
            control={control}
            errors={errors}
            isPrint
            values={formValues}
          />
        );
      case 11:
        return (
          <NutricionistaForm
            control={control}
            errors={errors}
            isPrint
            values={formValues}
          />
        );
      case 14:
        return (
          <PsicopedagogoForm
            control={control}
            errors={errors}
            isPrint
            values={formValues}
          />
        );
      case 6:
      case 8:
      case 9:
      case 13:
      case 16:
        return (
          <InspetorMonitorPorteiroServenteMerendeiraForm
            control={control}
            errors={errors}
            isPrint
            values={formValues}
          />
        );
      case 7:
      case 12:
      case 17:
        return (
          <OrientadorSupervisorInspetorForm
            control={control}
            errors={errors}
            isPrint
            values={formValues}
          />
        );
      case 15:
        return (
          <SecretarioEscolarForm
            control={control}
            errors={errors}
            isPrint
            values={formValues}
          />
        );
      default:
        return null;
    }
  };

  if (loading) {
    return (
      <Container maxWidth="md" sx={{ mt: 4, textAlign: "center" }}>
        <CircularProgress />
      </Container>
    );
  }

  return (
    <Container ref={componentRef} maxWidth="md" sx={{ mt: 4 }}>
      <Card>
        <CardContent>
          <Box sx={{ marginBottom: "20px", textAlign: "center" }}>
            <img
              src="https://app.semedspa.rj.gov.br/assets/images/prefeituras/semed.png"
              alt="Logo Prefeitura de São Pedro da Aldeia"
              style={{ maxWidth: "150px" }}
            />
            <Typography variant="h5">
              PREFEITURA MUNICIPAL DE SÃO PEDRO DA ALDEIA
            </Typography>
            <Typography variant="h6">SECRETARIA DE EDUCAÇÃO</Typography>
            <Typography variant="body1" gutterBottom>
              <b>PROCESSO SELETIVO SERVIDORES 2025 - SEMED</b>
            </Typography>
            <Typography variant="h6">
              Inscrição: <b>{id}</b> | Realizada em:{" "}
              <b>
                {new Date(formValues.dataCriacao).toLocaleDateString("pt-BR")}
              </b>{" "}
              às{" "}
              <b>
                {new Date(formValues.dataCriacao).toLocaleTimeString("pt-BR")}
              </b>
            </Typography>
          </Box>

          <Grid item xs={12}>
            <Typography style={{ marginBottom: "20px" }} variant="h6">
              Status: <b>{formValues.status}</b>
            </Typography>
          </Grid>

          <form>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  InputProps={{ readOnly: true }}
                  label="Nome completo"
                  variant="outlined"
                  defaultValue={formValues?.nomeCompleto}
                />
              </Grid>

              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  label="Data de Nascimento"
                  value={
                    new Date(formValues.dataNascimento).toLocaleDateString(
                      "pt-BR"
                    ) || ""
                  }
                  InputProps={{ readOnly: true }}
                />
              </Grid>

              <Grid item xs={12} sm={6}>
                <InputMask mask="999.999.999-99" value={formValues?.cpf}>
                  {() => (
                    <TextField
                      fullWidth
                      label="CPF"
                      variant="outlined"
                      InputProps={{ readOnly: true }}
                    />
                  )}
                </InputMask>
              </Grid>

              <Grid item xs={12} sm={6}>
                <InputMask mask="99999-999" value={formValues?.cep}>
                  {() => (
                    <TextField
                      fullWidth
                      label="CEP"
                      variant="outlined"
                      InputProps={{ readOnly: true }}
                    />
                  )}
                </InputMask>
              </Grid>

              <Grid item xs={12}>
                <TextField
                  fullWidth
                  label="Endereço (Logradouro)"
                  variant="outlined"
                  InputProps={{ readOnly: true }}
                  defaultValue={formValues?.endereco}
                />
              </Grid>

              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  label="Bairro"
                  variant="outlined"
                  InputProps={{ readOnly: true }}
                  defaultValue={formValues?.bairro}
                />
              </Grid>

              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  label="Cidade"
                  variant="outlined"
                  InputProps={{ readOnly: true }}
                  defaultValue={formValues?.cidade}
                />
              </Grid>

              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  label="Estado"
                  variant="outlined"
                  InputProps={{ readOnly: true }}
                  defaultValue={formValues?.estado}
                />
              </Grid>

              <Grid item xs={12} sm={6}>
                <InputMask mask="(99) 99999-9999" value={formValues?.telefone}>
                  {() => (
                    <TextField
                      fullWidth
                      label="Telefone (com DDD)"
                      variant="outlined"
                      InputProps={{ readOnly: true }}
                    />
                  )}
                </InputMask>
              </Grid>

              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  label="RG"
                  variant="outlined"
                  InputProps={{ readOnly: true }}
                  defaultValue={formValues?.rg}
                />
              </Grid>

              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  label="Título de Eleitor"
                  variant="outlined"
                  InputProps={{ readOnly: true }}
                  defaultValue={formValues?.tituloEleitor}
                />
              </Grid>

              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  label="PIS/PASEP"
                  variant="outlined"
                  InputProps={{ readOnly: true }}
                  defaultValue={formValues?.pisPasep}
                />
              </Grid>

              <Grid item xs={12} sm={6}>
                <Typography>Possui conta no Banco Santander?</Typography>
                <RadioGroup
                  row
                  value={formValues?.contaSantander}
                  InputProps={{ readOnly: true }}
                >
                  <FormControlLabel
                    value="SIM"
                    control={<Radio />}
                    label="Sim"
                  />
                  <FormControlLabel
                    value="NAO"
                    control={<Radio />}
                    label="Não"
                  />
                </RadioGroup>
              </Grid>

              <Grid item xs={12} sm={6}>
                <Typography>Pessoa com Deficiência (com laudo)?</Typography>
                <RadioGroup
                  row
                  value={formValues?.pessoaDeficiencia}
                  InputProps={{ readOnly: true }}
                >
                  <FormControlLabel
                    value="SIM"
                    control={<Radio />}
                    label="Sim"
                  />
                  <FormControlLabel
                    value="NAO"
                    control={<Radio />}
                    label="Não"
                  />
                </RadioGroup>
              </Grid>

              <Grid item xs={12}>
                <Typography variant="h5" textAlign="center" marginBottom={2}>
                  Cargo Pretendido
                </Typography>
                <Grid item xs={12} sm={12}>
                  <TextField
                    fullWidth
                    name="cargoPretendido"
                    label="Cargo Pretendido"
                    variant="outlined"
                    InputProps={{ readOnly: true }}
                    value={
                      cargosOptions?.find(
                        (c) => c.id == formValues?.cargoPretendido
                      )?.description || 0
                    }
                  />
                </Grid>
              </Grid>

              {renderCargoComponent()}

              <Grid item xs={12}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={formValues?.declaracao1 || false}
                      inputProps={{ readOnly: true }}
                    />
                  }
                  label="Declaro não ser servidor estatutário da Rede Municipal de Ensino de São Pedro da Aldeia."
                />
                {errors.declaracao1 && (
                  <FormHelperText>{errors.declaracao1.message}</FormHelperText>
                )}
                <Divider />

                <FormControlLabel
                  control={
                    <Checkbox
                      checked={formValues?.declaracao2 || false}
                      inputProps={{ readOnly: true }}
                    />
                  }
                  label="Declaro não ser ocupante de cargos comissionados da Prefeitura Municipal de São Pedro da Aldeia."
                />
                {errors.declaracao2 && (
                  <FormHelperText>{errors.declaracao2.message}</FormHelperText>
                )}
                <Divider />

                <FormControlLabel
                  control={
                    <Checkbox
                      checked={formValues?.declaracao3 || false}
                      inputProps={{ readOnly: true }}
                    />
                  }
                  label="Declaro estar ciente e aceitar as normas do Edital n.º 002/2024, assim como dos comunicados e outros informativos eventualmente divulgados, estando ciente de que todas as exigências deverão ser cumpridas, assumindo a responsabilidade pela veracidade das informações prestadas."
                />
                {errors.declaracao3 && (
                  <FormHelperText>{errors.declaracao3.message}</FormHelperText>
                )}
                <Divider />

                <FormControlLabel
                  control={
                    <Checkbox
                      checked={formValues?.declaracao4 || false}
                      inputProps={{ readOnly: true }}
                    />
                  }
                  label={
                    <>
                      Art. 48. A ausência de apresentação de documento que
                      comprove a habilitação para o cargo a que concorre, assim
                      como a não apresentação dos documentos pessoais,
                      acarretará a desclassificação do candidato.
                      <br />
                      Art. 49. O candidato que não comprovar documentalmente, no
                      ato da convocação, as informações prestadas na inscrição
                      para fins de titulação, nos casos em que se aplicar, e/ou
                      experiência em serviço, será desclassificado do certame.
                    </>
                  }
                />
                {errors.declaracao4 && (
                  <FormHelperText>{errors.declaracao4.message}</FormHelperText>
                )}
              </Grid>

              <style>
                {`
                  @media print {
                    .hide-on-print {
                      display: none !important;
                    }
                  }
              `}
              </style>

              <Grid className="hide-on-print" item xs={12}>
                {outrasInscricoes?.length > 0 && (
                  <Table style={{ marginTop: "20px" }}>
                    <TableHead>
                      <TableRow>
                        <TableCell align="center">ID</TableCell>
                        <TableCell align="center">Cargo</TableCell>
                        <TableCell align="center">Data de Inscrição</TableCell>
                        <TableCell align="center">Ações</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {outrasInscricoes?.map((inscricao) => (
                        <TableRow key={inscricao.id}>
                          <TableCell align="center">{inscricao.id}</TableCell>
                          <TableCell align="center">
                            {inscricao.cargoDescricao}
                          </TableCell>
                          <TableCell align="center">
                            {formatDateTime(inscricao.dataCriacao)}
                          </TableCell>
                          <TableCell align="center">
                            <Button
                              variant="outlined"
                              startIcon={<VisibilityIcon />}
                              onClick={() => handleNavigate(inscricao.id)}
                            >
                              Visualizar
                            </Button>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                )}
              </Grid>

              <Grid className="hide-on-print" item xs={12}>
                <Box gap={4} sx={{ display: "flex" }}>
                  <Button
                    onClick={() =>
                      navigate("/processo-seletivo-servidores/formulario")
                    }
                    variant="contained"
                    color="success"
                    fullWidth
                  >
                    Voltar
                  </Button>
                  <Button
                    onClick={handlePrint}
                    variant="contained"
                    color="primary"
                    fullWidth
                  >
                    Imprimir
                  </Button>
                </Box>
              </Grid>
            </Grid>
          </form>
        </CardContent>
      </Card>
    </Container>
  );
};

export default ProcessoSeletivoServidoresPageVisualizacao;
