import {
  Autocomplete,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from "@mui/material";
import { Controller } from "react-hook-form";
import {
  cincoCursos,
  experienceOptionsOutros,
  experienceOptionsPrefeitura,
} from "../data/options";

export function PsicopedagogoForm({
  control,
  errors,
  isPrint = false,
  values,
}) {
  return (
    <>
      <Grid item xs={12} sm={6}>
        <Typography marginBottom={2}>
          Experiência profissional no cargo pretendido na Prefeitura de São
          Pedro da Aldeia?
        </Typography>
        <Controller
          name="experiencePrefeitura"
          control={control}
          rules={{ required: "Experiência na Prefeitura é obrigatória." }}
          render={({ field }) =>
            isPrint ? (
              <TextField
                value={
                  experienceOptionsPrefeitura?.find(
                    (c) => c.id == values?.experiencePrefeitura
                  )?.description || 0
                }
                fullWidth
                variant="outlined"
                InputProps={{
                  readOnly: true,
                }}
              />
            ) : (
              <Autocomplete
                options={experienceOptionsPrefeitura}
                value={field.value.id}
                disableClearable
                inputValue={field.value.description}
                getOptionLabel={(option) => option.description}
                isOptionEqualToValue={(option, value) =>
                  option.id === value?.id
                }
                onChange={(event, newValue) =>
                  field.onChange(newValue ? newValue.id : null)
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Selecione uma opção..."
                    variant="outlined"
                    error={!!errors.experiencePrefeitura}
                    helperText={
                      errors.experiencePrefeitura
                        ? errors.experiencePrefeitura.message
                        : ""
                    }
                  />
                )}
              />
            )
          }
        />
      </Grid>

      <Grid item xs={12} sm={6}>
        <Typography marginBottom={2}>
          Experiência profissional no cargo pretendido em outros municípios ou
          instituições privadas?
        </Typography>
        <Controller
          name="experienceOutros"
          control={control}
          rules={{
            required: "Experiência em outros municípios é obrigatória.",
          }}
          render={({ field }) =>
            isPrint ? (
              <TextField
                value={
                  experienceOptionsOutros?.find(
                    (c) => c.id == values?.experienceOutros
                  )?.description || 0
                }
                fullWidth
                variant="outlined"
                InputProps={{
                  readOnly: true,
                }}
              />
            ) : (
              <Autocomplete
                options={experienceOptionsOutros}
                value={field.value.id}
                disableClearable
                inputValue={field.value.description}
                getOptionLabel={(option) => option.description}
                isOptionEqualToValue={(option, value) =>
                  option.id === value?.id
                }
                onChange={(event, newValue) =>
                  field.onChange(newValue ? newValue.id : null)
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Selecione uma opção..."
                    variant="outlined"
                    error={!!errors.experienceOutros}
                    helperText={
                      errors.experienceOutros
                        ? errors.experienceOutros.message
                        : ""
                    }
                  />
                )}
              />
            )
          }
        />
      </Grid>

      <Grid item xs={12} sm={6}>
        <Typography>
          Cursos de aperfeiçoamento na área de Educação, com carga horária
          mínima de 40h (quarenta horas) - por certificado - concluídos no
          período entre 2014 e a data inicial de inscrição deste processo
          seletivo simplificado
        </Typography>
        <Controller
          name="cursoEducacao"
          control={control}
          rules={{ required: "Selecione um curso de educação." }}
          render={({ field }) =>
            isPrint ? (
              <TextField
                value={
                  cincoCursos?.find((c) => c.id == values?.cursoEducacao)
                    ?.description || ""
                }
                fullWidth
                variant="outlined"
                InputProps={{
                  readOnly: true,
                }}
              />
            ) : (
              <Autocomplete
                options={cincoCursos}
                value={field.value.id}
                disableClearable
                inputValue={field.value.description}
                getOptionLabel={(option) => option.description}
                isOptionEqualToValue={(option, value) =>
                  option.id === value?.id
                }
                onChange={(event, newValue) =>
                  field.onChange(newValue ? newValue.id : null)
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Selecione uma opção..."
                    variant="outlined"
                    error={!!errors.cursoEducacao}
                    helperText={
                      errors.cursoEducacao ? errors.cursoEducacao.message : ""
                    }
                  />
                )}
              />
            )
          }
        />
      </Grid>

      <Grid item xs={12} sm={6}>
        <Typography>Pós-Graduação Lato Sensu na área da Educação - concluída</Typography>
        <Controller
          name="posGraduacao"
          control={control}
          rules={{ required: "Obrigatório selecionar uma opção." }}
          render={({ field }) =>
            isPrint ? (
              <RadioGroup row value={values.posGraduacao}>
                <FormControlLabel value="SIM" control={<Radio />} label="Sim" />
                <FormControlLabel value="NAO" control={<Radio />} label="Não" />
              </RadioGroup>
            ) : (
              <FormControl error={!!errors.posGraduacao}>
                <RadioGroup
                  row
                  {...field}
                  onChange={(e) => field.onChange(e.target.value)}
                >
                  <FormControlLabel
                    value="sim"
                    control={<Radio />}
                    label="Sim"
                  />
                  <FormControlLabel
                    value="nao"
                    control={<Radio />}
                    label="Não"
                  />
                </RadioGroup>
                {errors.posGraduacao && (
                  <FormHelperText>{errors.posGraduacao.message}</FormHelperText>
                )}
              </FormControl>
            )
          }
        />
      </Grid>

      <Grid item xs={12} sm={6}>
        <Typography>
          Pós-Graduação Strictu Sensu – Mestrado - concluída
        </Typography>
        <Controller
          name="mestrado"
          control={control}
          rules={{ required: "Obrigatório selecionar uma opção." }}
          render={({ field }) =>
            isPrint ? (
              <RadioGroup row value={values.mestrado}>
                <FormControlLabel value="SIM" control={<Radio />} label="Sim" />
                <FormControlLabel value="NAO" control={<Radio />} label="Não" />
              </RadioGroup>
            ) : (
              <FormControl error={!!errors.mestrado}>
                <RadioGroup
                  row
                  {...field}
                  onChange={(e) => field.onChange(e.target.value)}
                >
                  <FormControlLabel
                    value="sim"
                    control={<Radio />}
                    label="Sim"
                  />
                  <FormControlLabel
                    value="nao"
                    control={<Radio />}
                    label="Não"
                  />
                </RadioGroup>
                {errors.mestrado && (
                  <FormHelperText>{errors.mestrado.message}</FormHelperText>
                )}
              </FormControl>
            )
          }
        />
      </Grid>

      <Grid item xs={12} sm={6}>
        <Typography>
          Pós-Graduação Strictu Sensu – Doutorado - concluída
        </Typography>
        <Controller
          name="doutorado"
          control={control}
          rules={{ required: "Obrigatório selecionar uma opção." }}
          render={({ field }) =>
            isPrint ? (
              <RadioGroup row value={values.doutorado}>
                <FormControlLabel value="SIM" control={<Radio />} label="Sim" />
                <FormControlLabel value="NAO" control={<Radio />} label="Não" />
              </RadioGroup>
            ) : (
              <FormControl error={!!errors.doutorado}>
                <RadioGroup
                  row
                  {...field}
                  onChange={(e) => field.onChange(e.target.value)}
                >
                  <FormControlLabel
                    value="sim"
                    control={<Radio />}
                    label="Sim"
                  />
                  <FormControlLabel
                    value="nao"
                    control={<Radio />}
                    label="Não"
                  />
                </RadioGroup>
                {errors.doutorado && (
                  <FormHelperText>{errors.doutorado.message}</FormHelperText>
                )}
              </FormControl>
            )
          }
        />
      </Grid>
    </>
  );
}
